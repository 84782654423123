import React, { useEffect, useState, useContext } from "react";
import Auth from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../features/userSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  let navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  // const emailValidation = (email) => {
  //   const regEx = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+/;
  //   if (regEx.test(email)) {
  //     setMessage("Email is valid");
  //   } else if (!regEx.test(email)) {
  //     setMessage("Email or Password is invalid");
  //   } else {
  //     setMessage("");
  //   }
  // };
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(userLogin(user));
  };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/account");
  //   }
  // }, [isAuthenticated, navigate]);

  const handleRedirect = () => {
    navigate("/register");
  };

  return (
    <div className="tab-content">
      <form className="register--form" onSubmit={handleSubmit}>
        <h1 className="register--title">Identifiez-vous</h1>

        <label className="labelForm" htmlFor="email">
          Email
        </label>
        <input
          type="text"
          name="email"
          className="formInput"
          id="email"
          placeholder="mail@mail.fr"
          onChange={handleChange}
        />

        <label className="labelForm" htmlFor="password">
          Mot de passe
        </label>
        <input
          type="password"
          name="password"
          className="formInput"
          id="password"
          placeholder="Mot de passe"
          onChange={handleChange}
        />
        <button
          type="submit"
          // onClick={emailValidation}
          className="login--submit"
        >
          Se connecter
        </button>
        <button
          type="submit"
          // onClick={emailValidation}
          className="login--submit"
          onClick={handleRedirect}
        >
          S'enregistrer
        </button>
        {message}
      </form>
    </div>
  );
};

export default Login;
