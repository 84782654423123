import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../../contexts/Auth";
import { useSelector } from "react-redux";

const AuthenticatedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(Auth);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/account" />;
  }
  return <Outlet />;

  // return isAuthenticated ? (
  //   <Routes>
  //     <Route path={path} component={component} />
  //   </Routes>
  // ) : (
  //   <Navigate to='/login' />
  // );
};

export default AuthenticatedRoute;
