import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Auth from "../../contexts/Auth";
import { logout } from "../../services/AuthApi";
import { userLogout } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./navBar.scss";
const Navbar = () => {
  // const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
  // const [pseudo, setPseudo] = useState("");
  // useEffect(() => {
  //   fetch("http://localhost:3001/api/users/login").then((response) => {
  //     console.log(response);
  //   });
  // }, []);
  // const handleLogout = () => {
  //   logout();
  //   setIsAuthenticated(false);
  // };

  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
 /*  console.log(isAuthenticated); */

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <div className="collapse navbar-collapse" id="navbarColor01">
        <ul className="navbar-nav mr-auto">
          {/* <li className="nav-item"> */}
          {/* <NavLink className="nav-link" to="/">
              Accueil
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/account/favoris">
              Favoris
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto"> */}
          {(!isAuthenticated && (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/login">
                  <AccountCircleIcon fontSize="large" />
                </NavLink>
              </li>
            </>
          )) || (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/account">
                  Mon compte
                </NavLink>
              </li>
              <li className="nav-item">
                <button className="btn btn-danger" onClick={handleLogout}>
                  Déconnexion
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
