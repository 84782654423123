import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./app.css";
// import Auth from "../../contexts/Auth";
import { register } from "../../services/AuthApi";
import FormInput from "./FormInput";
import Login from "./Login";

const Register = () => {
  // let navigate = useNavigate();
  // const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    username: "",
    email: "",
    lastname: "",
    firstname: "",
    password: "",
    passwordConfirm: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "Username",
      errorMessage:
        "Le nom d'utilisateur doit comporter entre 3 et 16 caractères et ne doit pas inclure de caractères spéciaux !",
      label: "Username",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "lastname",
      type: "text",
      placeholder: "Nom",
      errorMessage: "Il doit s'agir d'un nom valide !",
      label: "Nom",
      required: true,
    },
    {
      id: 3,
      name: "firstname",
      type: "text",
      placeholder: "Prénom",
      errorMessage: "Il doit s'agir d'un prénom valide !",
      label: "Prénom",
      required: true,
    },
    {
      id: 4,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Il doit s'agir d'une adresse e-mail valide !",
      label: "Email",
      required: true,
    },
    {
      id: 5,
      name: "password",
      type: "password",
      placeholder: "Mot de passe",
      errorMessage: "Le mot de passe doit être composé de 6 à 20 caractères et inclure au moins 1 lettre, 1 chiffre et 1 caractère spécial !",
      label: "Mot de passe",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 6,
      name: "passwordConfirm",
      type: "password",
      placeholder: "Confirmer le mot de passe",
      errorMessage: errorMessage,
      label: "Confirmer le mot de passe",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await register(values);
      setSuccess(true);
      setValues("");
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (!e.target.name === "passwordConfirm"){}
    if (values.password != values.passwordConfirm){
      setErrorMessage("Les mots de passe ne correspondent pas !");
    }

  };
  
  return (
    <>
      {success ? (
        <Login />
      ) : (
        <div className="tab-content">
          <form className="register--form" onSubmit={handleSubmit}>
            <h1 className="register--title">S'enregister</h1>
            {inputs.map((input) => (
              <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
              />
            ))}
            <button className="register--submit">Envoyer</button>
          </form>
        </div>
      )}
    </>
  );
};

// const Register = (props) => {
//   return (
//     <div className='tab-content'>
//       <form className='form-profile'>
//         <fieldset>
//           <legend>S'enregistrer</legend>
//           <div className='form-group'>
//             <label htmlFor='email'>Nom</label>
//             <input
//               type='text'
//               className='form-control'
//               id='email'
//               placeholder='Nom'
//             />
//           </div>
//           <div className='form-group'>
//             <label htmlFor='email'>Prénom</label>
//             <input
//               type='text'
//               className='form-control'
//               id='email'
//               placeholder='Prénom'
//             />
//           </div>
//           <div className='form-group'>
//             <label htmlFor='email'>Email</label>
//             <input
//               type='text'
//               className='form-control'
//               id='email'
//               placeholder='mail@mail.fr'
//             />
//           </div>
//           <div className='form-group'>
//             <label htmlFor='password'>Password</label>
//             <input
//               type='password'
//               className='form-control'
//               id='password'
//               placeholder='Password'
//             />
//           </div>
//           <div className='form-group'>
//             <label htmlFor='password'>Confirm Password</label>
//             <input
//               type='password'
//               className='form-control'
//               id='password'
//               placeholder='Password'
//             />
//           </div>
//           <button type='submit' className='btn btn-outline-primary'>
//             Save
//           </button>
//         </fieldset>
//       </form>
//     </div>
//   );
// };

export default Register;
