import React from "react";
import { useState } from "react";
import Profile from "./Profile";
import Posts from "../posts/Posts";
import Voyages from "../Voyages/Voyages";
import Avis from "../Avis/Avis";

const Account = (props) => {
  const [curentTab, setCurrentTab] = useState({ name: "profile" });
  const [tabs] = useState([
    { tabKey: "profile", tabTitle: "Mon profil" },
    { tabKey: "posts", tabTitle: "Mes Favoris" },
    { tabKey: "avis", tabTitle: "Mes Avis" },
    { tabKey: "trips", tabTitle: "Mes voyages" },
  ]);
  const handleTabs = (name) => {
    setCurrentTab({ name });
  };
  return (
    <>
      <div className="tabs">
        <ul>
          {tabs.map((tab, index) => (
            <li
              key={`account-tabs-pane--${index}`}
              className={`tabs-pane ${
                curentTab.name === tab.tabKey ? "active" : ""
              }`}
            >
              <span onClick={() => handleTabs(tab.tabKey)}>{tab.tabTitle}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="tabs-contents">
        {curentTab.name === "profile" && <Profile />}
        {curentTab.name === "posts" && <Posts />}
        {curentTab.name === "trips" && <Voyages />}
        {curentTab.name === "avis" && <Avis />}
      </div>
    </>
  );
};

export default Account;
