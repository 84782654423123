import React from "react";
import { Link } from "react-router-dom";

const Posts = (props) => {
  return (
    <div className="card-container">
      <Link to={"/destinations/282/hotels"} className="card border-info mb-3">
        <div className="card-header">Annecy</div>
        <div className="card-body">
          <p className="card-text">Hotels</p>
        </div>
      </Link>
      <Link
        to={"/destinations/378/restaurants"}
        className="card border-info mb-3"
      >
        <div className="card-header">Alaska</div>
        <div className="card-body">
          <p className="card-text">Restaurants</p>
        </div>
      </Link>
      <Link
        to={"/destinations/323/activities"}
        className="card border-info mb-3"
      >
        <div className="card-header">Bergen</div>
        <div className="card-body">
          <p className="card-text">Activités</p>
        </div>
      </Link>
    </div>
  );
};

export default Posts;
