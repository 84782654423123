import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./profile.scss";

const Profile = (props) => {
  const [userName, setUserme] = useState("");
  const pseudo = useSelector((state) => state.auth.user.username);

  return (
    <div className="profil-tab-content">
      <div className="profil border-info mb-3">
        <div className="profil-header"></div>
        <div className="profil-body">
          <h4 className="profil-title">Bonjour, {pseudo}</h4>
        </div>
      </div>
    </div>
  );
};

export default Profile;
