import axios from "axios";
import jwtDecode from "jwt-decode";
import { getItem, addItem, removeItem } from "./LocaleStorage";
// const BASE_URL = "http://localhost:3001/api/users/login";

export function hasAuthenticated() {
  const token = getItem("MyJourneyToken");
  const result = token ? tokenIsValid(token) : false;

  if (false === result) {
    logout();
  }

  return result;
}

// export const login = axios.post({
//   baseURL: BASE_URL,
//   header: { "Content-Type": "application/json" },
//   withCredentials: true,
// });

export async function login(credentials) {
  const response = await axios.post(
    "https://my-journey-api.loicponsot.fr/api/users/login",
    credentials
  );

  addItem("MyJourneyToken", response.data);

  return response.data;
}

export async function loginUsername(credentials) {
  const response = await axios.get(
    "https://my-journey-api.loicponsot.fr/api/users/login",
    credentials
  );
  return response.data.username;
}
// export async function account() {
//   const response = await axios.post("http://localhost:3001/api/account/journey", {
//     headers: { authorization: `Bearer ${token}` },
//   });
//   const token = response.data.authorization;
//   console.log(response);

//   addItem("MyJourneyToken", token);
//   return true;
// }

export async function register(credentials) {
  const response = await axios.post(
    "https://my-journey-api.loicponsot.fr/api/users/register",
    credentials
  );
  return true;
}

export function logout() {
  removeItem("MyJourneyToken");
}

function tokenIsValid(token) {
  const { exp: expiration } = jwtDecode(token);

  if (expiration * 1000 > new Date().getTime()) {
    return true;
  }

  return false;
}
// export async function login() {
//   const response = await axios.post({
//     baseURL: BASE_URL,
//     header: { "Content-Type": "application/json" },
//     withCredentials: true,
//   });
//   const token = response.data.token;
//   console.log("token :", token);
//   addItem("MyJourneyToken", token);
//   return true;
// }
// export async function login(credentials) {
//   console.log("send request login with", { credentials });
//   try {
//     const response = await axios.post("http://localhost:3001/api/users/login", {
//       credentials,
//     });
//     const token = response.data.token;
//     addItem("miniblogToken", token);
//     return response;
//   } catch (err) {
//     return err.response;
