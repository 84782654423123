import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login, logout } from "../services/AuthApi";

// assign localStorage value to the user variable
const user = JSON.parse(localStorage.getItem("MyJourneyToken"));
// initialise the state to equal user data from localStorage
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

// Functions that takes credentials as params and uses authService to call the API
export const userLogin = createAsyncThunk(
  "/login",
  async (credentials, thunkAPI) => {
    try {
      const data = await login(credentials);
      return { user: data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Calls function that removes user data
export const userLogout = createAsyncThunk("/logout", async () => {
  await logout();
});

// Info that will be given to the store
export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [userLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [userLogout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export default userSlice.reducer;
