import { Link } from "react-router-dom";
import "./destinationSearch.scss";

import * as React from "react";

/* import Box from "@mui/material/Box";
import SearchBar from "../Home/SearchBar/SearchBar"; */

export default function DestinationSearch({data}) {

  /* const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",

    boxShadow: 24,
  }; */
  
  /* <Box sx={style}>
    <SearchBar setDestinationData={ setDestinationData }/>
  </Box> */
  
  if (!data.length) 
    return (
      <div className="destination-search-empty">
        <p>Nous ne sommes pas parvenu à trouver de destinations correspondantes à votre recherche</p>
      </div>      
    );

  return (
    <div className="destination-search">
      <div className="all-box">
        {data.map((dest) => (
          <div key={dest.id} className="box">
            <Link to={`/destinations/${dest.id}`}>
              <img
                src={`https://my-journey-api.loicponsot.fr/images/${dest.destination_visual}`}
                alt={`visual of ${dest.city_name}`}
              />
              <p>{dest.city_name}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
